import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import BookingStepper from "./BookingStepper";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, updateDoc, orderBy, startAt, endAt, documentId, Timestamp, and } from 'firebase/firestore';
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled, TextField } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Fab from '@mui/material/Fab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import Messaging from '../components/Messaging';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LoginIcon from '@mui/icons-material/Login';
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import {AddressInput} from "./google_maps/MapComponents";
import Checkbox from '@mui/material/Checkbox';
import ScheduleCard from "./ScheduleCard";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AvailableTreatments = ({services, setSelectedTreatments, scrollRef}) => {
  //const [availableService, setAvailableService] = React.useState(services);
  const [checked, setChecked] = React.useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedTreatments(newChecked);
    
    //console.log('scrollRef', scrollRef);
    //scroll
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 10000;
    }
  };

  //console.log('available services', services);
  return (
    <>
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {services.map((item, index) => {
        const labelId = `checkbox-list-secondary-label-${index}`;
        return (
        <>
          <ListItem alignItems="flex-start" key={index}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(index)}
                checked={checked.indexOf(index) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding>
            <ListItemAvatar>
              <Avatar alt={item.name} src={item.image} />
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={
                <React.Fragment>
                  ${item.price}
                </React.Fragment>
              }
            />
          </ListItem>
          {index<(services.length-1) && <Divider variant="middle" component="li" />}
        </>
      )})}
    </List>
    </>
  )
}

const Item = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center'
}));

const FlexboxItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  //color: theme.palette.text.secondary,
  border: '1px solid rgba(24, 24, 24, 0.5)',
  color: '#181818',
  flexGrow: 1,
  width: '150px',
  cursor: 'pointer',
}));

const FlexboxItemText = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.26)',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  flexGrow: 1,
  width: '150px',
}));

const FlexdivItem = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
  width: '300px',
}));

export default function InfoDialog(props) {
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const { authUser } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const signInPath = config.pathnames.SignIn;
  const [open, setOpen] = React.useState(false);
  const [actionBooking, setActionBooking] = React.useState(false);
  const [bookingTimeSlot, setBookingTimeSlot] = React.useState(null);
  const [bookingTimeSlotNumber, setBookingTimeSlotNumber] = React.useState(null);
  const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
  const [step, setStep] = useState(0);
  const [clear, setClear] = useState(false);
  const [schedule, setSchedule] = React.useState(null);
  const [timeslotSnackbarMessage, setTimeslotSnackbarMessage] = useState("Booking Session Timeout. Please book again.");
  const [openTimeslotSnackbar, setOpenTimeslotSnackbar] = React.useState(false);
  const bookingTimeSlots = [
    '8:00am - 10:00am',
    '10:00am - 12:00pm',
    '12:00pm - 2:00pm',
    '2:00pm - 4:00pm',
    '4:00pm - 6:00pm',
    '6:00pm - 8:00pm',
    '8:00pm - 10:00pm'
  ];

  const defaultAvailableTimeSlots = [];
  //const userAvailableTimeSlots = useRef();
  bookingTimeSlots.forEach((timeSlot) => {
    const defaultAvailableTimeSlot = {
      timeSlot: timeSlot,
      userId: null,
    }
    defaultAvailableTimeSlots.push(defaultAvailableTimeSlot);
  });

  //userAvailableTimeSlots.current = [...defaultAvailableTimeSlots];

  const [availableTimeSlots, setAvailableTimeSlots] = React.useState(defaultAvailableTimeSlots);
  const [allAvailableTimeslots, setAllAvailableTimeslots] = React.useState([]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllAvailableTimeslotsFromDB = () => {
    const providers = props.dialogInfo.providers;
    //console.log('providers', providers);
    
    const providerUids = [];
    providers.forEach((provider) => {
      providerUids.push(provider.user_id);
      //console.log('provider.user_id', provider.user_id);
    });
    //get all available time slots of the user_ids in the day
    if (providerUids.length > 0) {
      getAllAvailableTimeSlots(providerUids).then((timeSlots) => {
        //console.log('timeSlots', timeSlots);
        setAllAvailableTimeslots(timeSlots);
      });
    }
    
    async function getAllAvailableTimeSlots(providerUids) {
      //console.log('providerUids', providerUids);
      const agendaRef = collection(firestoreInstance, '/agenda');
      const agendaQuery = query(agendaRef, 
        where(documentId(), 'in', providerUids));

      const timeSlots = [];
      await Promise.all([getDocs(agendaQuery)]).then(([snapshot]) => {
          snapshot.forEach(record => {
              timeSlots.push({
                slots: record.data().timeSlots,
                user_id: record.id,
              });
          });
          //console.log('timeSlots', timeSlots);
      }).catch(error => {
          console.log('error', error);
      });
      return timeSlots;
    }
  }

  useEffect(() => {
    if (props.openDialog) {
        setOpen(props.openDialog);
        props.handleOpenDialog(false);
        setAllAvailableTimeslots([]);
        let userAvailableTimeSlots = [...defaultAvailableTimeSlots]; //reset
        setAvailableTimeSlots(userAvailableTimeSlots); //reset userAvailableTimeSlots
        if (props.dialogInfo.product_id) {
          setStep(0);
          setBookingTimeSlot(null);
          setBookingTimeSlotNumber(null);
          setSelectedDay(dayjs(new Date()));
          //get available timeslots here
          getAllAvailableTimeslotsFromDB();
        }
        //console.log('info', props.dialogInfo, props.dialogInfo.product_id);
    }
  }, [props.openDialog]);

  useEffect(() => {
    //console.log('availableTimeSlots', availableTimeSlots);
  }, [availableTimeSlots]);

  const ref = React.useRef(null);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (open) {
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
    }
  }, [open, step]);

  

  const selectDay = (day) => {
    //get available timeslots here
    setAllAvailableTimeslots([]);
    getAllAvailableTimeslotsFromDB();
    setSelectedDay(day);
  }

  useEffect(() => {
    //console.log('selectedDay', selectedDay, selectedDay.format('YYYY-MM-DD ZZ'));
    if (!props.dialogInfo) return; 
    let userAvailableTimeSlots = [...defaultAvailableTimeSlots]; //reset
    //setAvailableTimeSlots(userAvailableTimeSlots); //reset userAvailableTimeSlots
    //console.log('selectedDay', selectedDay, selectedDay.format('YYYY-MM-DD ZZ'));

    let appointmentsOfTheDay = [];
    const getAppointmentOfTheDayByUser = async (userId) => {
      //get user's schedule of the day
      const appointments = [];
      const schedulesRef = collection(firestoreInstance, '/schedules');
      const scheduleQuery = query(schedulesRef, 
        and(where('date', '==', selectedDay.format('MMM D')), where('year', '==', selectedDay.format('YYYY')), where('treatment.provider.user_id', '==', userId)));

      await Promise.all([getDocs(scheduleQuery)]).then(([snapshot]) => {
          snapshot.forEach(record => {
              const schedule = record.data();
              if (record.id.length > 0) {
                //console.log('schedule', record.id);
                appointments.push(schedule);
              }
          });
      }).catch(error => {
          console.log('error', error);
      });
      return appointments;
    }

    const getTimeSlotsForDefaultServiceProvider = async () => {
      //console.log('props.dialogInfo.provider.user_id', props.dialogInfo.provider.user_id);
      await Promise.all([getAppointmentOfTheDayByUser(props.dialogInfo.provider.user_id)]).then(([appointments]) => {
        //console.log('appointments', appointments);
        appointmentsOfTheDay = [...appointments];
        //console.log('appointmentsOfTheDay', appointmentsOfTheDay);
  
        const defaultProviderAvailableTimeslots = allAvailableTimeslots.filter((availableTimeslot) => availableTimeslot.user_id === props.dialogInfo.provider.user_id);
        //console.log('defaultProviderAvailableTimeslots', defaultProviderAvailableTimeslots);
        
        //get timeslots for the day
        if (defaultProviderAvailableTimeslots.length > 0) {
          defaultProviderAvailableTimeslots.forEach((userTimeslots)=>{
            const slots=userTimeslots.slots;
            const user_id=userTimeslots.user_id;
            
            const slotsOfTheDay = slots.filter((timeslot) => timeslot.day === selectedDay.hour(0).minute(0).second(0).millisecond(0).unix());
            //console.log('slotsOfTheDay 1', slotsOfTheDay);
            slotsOfTheDay.forEach((timeSlot) => {
              const slots = timeSlot.slots;
      
              slots.forEach((slot) => {
                //console.log('slot',slot);
                // appointmentsOfTheDay can be null
                if (appointmentsOfTheDay.length > 0) {
                  appointmentsOfTheDay.forEach((appointment) => {
                    if (appointment.time != userAvailableTimeSlots[slot.timeSlot].timeSlot) {
                      userAvailableTimeSlots[slot.timeSlot].userId=user_id;
                    }
                  });
                } else {
                  userAvailableTimeSlots[slot.timeSlot].userId=user_id;
                }
                
                //console.log('userAvailableTimeSlot default', userAvailableTimeSlots[slot.timeSlot]);
                //setAvailableTimeSlots(userAvailableTimeSlots);
    
                //---
                
                //---
    
              });
            });
          });
        }
        
        //console.log('userAvailableTimeSlots default provider', userAvailableTimeSlots);
  
      }).catch(error => {
          console.log('error', error);
      }); 
    }

    const getTimeSlots = async () => {
      //1. get time slots for default nurse
      await Promise.all([getTimeSlotsForDefaultServiceProvider()]).then(() => {
        
      }).catch(error => {
          console.log('error', error);
      });

      //console.log('step 1 done');
      //console.log('userAvailableTimeSlots', userAvailableTimeSlots);

      //2. get time slots for the rest providers

      //---------------------

      const candidateProviderAvailableTimeslots = allAvailableTimeslots.filter((availableTimeslot) => availableTimeslot.user_id !== props.dialogInfo.provider.user_id);
      //console.log('candidateProviderAvailableTimeslots', candidateProviderAvailableTimeslots);

      const candidateUids = [];
      candidateProviderAvailableTimeslots.forEach((candidate) => {
        candidateUids.push(candidate.user_id);
      });

      //console.log('candidateUids', candidateUids);

      if (candidateUids.length !== 0) {
        for (let step = 0; step < candidateUids.length; step++) {
          const uid = candidateUids[step];
          await Promise.all([getAppointmentOfTheDayByUser(uid)]).then(([appointments]) => {
            appointmentsOfTheDay = [...appointments];
            //console.log('appointmentsOfTheDay the rest', appointmentsOfTheDay);
            //get timeslots for the day
            candidateProviderAvailableTimeslots.forEach((userTimeslots)=>{
              const slots=userTimeslots.slots;
              const user_id=userTimeslots.user_id;
  
              const slotsOfTheDay = slots.filter((timeslot) => timeslot.day === selectedDay.hour(0).minute(0).second(0).millisecond(0).unix());
              //console.log('slotsOfTheDay 2', slotsOfTheDay);
              slotsOfTheDay.forEach((timeSlot) => {
                const slots = timeSlot.slots;
                slots.forEach((slot) => {
                  //console.log('slot.timeSlot',slot.timeSlot);
                  if (appointmentsOfTheDay.length > 0) {
                    appointmentsOfTheDay.forEach((appointment) => {
                      //console.log('appointment the rest', appointment);
                      if (userAvailableTimeSlots[slot.timeSlot].userId === null) {
                        //console.log('userAvailableTimeSlots[slot.timeSlot]', userAvailableTimeSlots[slot.timeSlot]);
                        if (appointment.time != userAvailableTimeSlots[slot.timeSlot].timeSlot) {
                          userAvailableTimeSlots[slot.timeSlot].userId=user_id;
                        }
                      }
                    });
                  } else {
                    if (userAvailableTimeSlots[slot.timeSlot].userId === null) {
                      userAvailableTimeSlots[slot.timeSlot].userId=user_id;
                    }
                  }
                  //console.log('userAvailableTimeSlot', userAvailableTimeSlots[slot.timeSlot]);
                });
              });
              //console.log('userAvailableTimeSlots', userAvailableTimeSlots);
            });
          });
        }
      }

      //---------------------

      //console.log('step 2 done');

      //--update data--
      const selectedDayIsToday = selectedDay.hour(0).minute(0).second(0).millisecond(0).unix() == dayjs().hour(0).minute(0).second(0).millisecond(0).unix();
      //console.log('selectedDayIsToday', selectedDayIsToday);

      let userAvailableTimeSlotsFiltered = [];
      if (selectedDayIsToday) {
        //get only coming timeslots
        let timeslotIndexOfCurrentTime = -1;
        if (dayjs().hour() >= 8) timeslotIndexOfCurrentTime = 0;
        if (dayjs().hour() >= 10) timeslotIndexOfCurrentTime = 1;
        if (dayjs().hour() >= 12) timeslotIndexOfCurrentTime = 2;
        if (dayjs().hour() >= 14) timeslotIndexOfCurrentTime = 3;
        if (dayjs().hour() >= 16) timeslotIndexOfCurrentTime = 4;
        if (dayjs().hour() >= 18) timeslotIndexOfCurrentTime = 5;
        if (dayjs().hour() >= 20) timeslotIndexOfCurrentTime = 6;
        userAvailableTimeSlotsFiltered = userAvailableTimeSlots.map((timeslot, index) => {
          if (index <= timeslotIndexOfCurrentTime) {
            timeslot.userId = null;
          }
          return timeslot;
        });
        //console.log('userAvailableTimeSlotsFiltered', userAvailableTimeSlotsFiltered);
        setAvailableTimeSlots(userAvailableTimeSlotsFiltered);
      } else {
        userAvailableTimeSlotsFiltered = userAvailableTimeSlots.map((timeslot, index) => {
          return timeslot;
        });
        //console.log('userAvailableTimeSlotsFiltered', userAvailableTimeSlotsFiltered);
        setAvailableTimeSlots(userAvailableTimeSlotsFiltered);
      }
      
      //--update data--
    }

    getTimeSlots();

    //console.log('time starts');

  }, [selectedDay, allAvailableTimeslots]);

  const startBooking = async (timeSlot, userId) => {
    //check agenda doc to see if there's any nurse available in this time slot???
    //also check appointment, this time slot is not used
    const schedulesRef = collection(firestoreInstance, '/schedules');
    const scheduleQuery = query(schedulesRef, 
      and(where('date', '==', selectedDay.format('MMM D')), where('year', '==', selectedDay.format('YYYY')), where('time', '==', bookingTimeSlots[timeSlot-1]), where('treatment.provider.user_id', '==', userId)));

      /*
      
        const start = Date.now();
        let i = 0;
        function res(n) {
          const id = ++i
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true)
              console.log(`res #${id} called after ${n} milliseconds`, Date.now() - start)
            }, n)
          })
        }

        await Promise.all([res(3000), getDocs(scheduleQuery)]).then(([r, snapshot]) => {
            console.log('r', r);
            snapshot.forEach(record => {
                //const schedule = record.data();
                if (record.id.length > 0) {
                  //console.log('schedule', record.id);
                  slotIsBooked = true;
                }
            });
        }).catch(error => {
            console.log('error', error);
        });
      */
    
    let slotIsBooked = false;
    await Promise.all([getDocs(scheduleQuery)]).then(([snapshot]) => {
        snapshot.forEach(record => {
            //const schedule = record.data();
            if (record.id.length > 0) {
              //console.log('schedule', record.id);
              slotIsBooked = true;
            }
        });
    }).catch(error => {
        console.log('error', error);
    });
    // slotIsBooked will wait till checking schedule is done
    //console.log('slotIsBooked', slotIsBooked);
    if (!slotIsBooked) {
      setBookingTimeSlot(bookingTimeSlots[timeSlot-1]);
      setBookingTimeSlotNumber(timeSlot);
      setStep(1);
    } else {
      //get another service?
      //let userAvailableTimeSlots = [...availableTimeSlots];
      //timeslotSnackbarMessage
      setOpenTimeslotSnackbar(true);
    }

  }

  const addressEnteredRef = React.useRef(null);
  const guestFullnameRef = React.useRef(null);
  const guestEmailRef = React.useRef(null);
  const guestPhoneNumberRef = React.useRef(null);
  const selectedTreatmentsRef = React.useRef(null);

  const setAddressEntered = (address) => {
    addressEnteredRef.current = address;
  }

  const setGuestFullname = (fullname) => {
    guestFullnameRef.current = fullname;
  }

  const setGuestEmail = (email) => {
    guestEmailRef.current = email;
  }

  const setGuestPhoneNumber = (phoneNumber) => {
    guestPhoneNumberRef.current = phoneNumber;
  }

  const createSchedule = async () => {
    setActionBooking(true);
    //make the treatment data according to which servicer provider has been picked
    let treatment = structuredClone(props.dialogInfo);
    const treatmentProvider = treatment.provider;
    const treatmentProviders = treatment.providers;
    if (treatmentProvider.user_id != availableTimeSlots[bookingTimeSlotNumber - 1].userId) {
      const newProvider = treatmentProviders.find((element) => element.user_id == availableTimeSlots[bookingTimeSlotNumber - 1].userId);
      treatment.provider = newProvider;
      //console.log('replace default provider with new data', newProvider);
    }
    

    //console.log('treatment', treatment);
    //console.log('AvailableTimeSlots', availableTimeSlots);
    //console.log('Selected TimeSlot', availableTimeSlots[bookingTimeSlotNumber - 1]);
    
    delete treatment.users;
    const totalPrice = props.dialogInfo.price;

    /*
    const treatments = [];
    //console.log('props.dialogInfo', props.dialogInfo);
    const provider = {};
    provider.address = props.dialogInfo.address;
    provider.serviceAccount = props.dialogInfo.serviceAccount;
    provider.user = props.dialogInfo.user;
    provider.user_id = props.dialogInfo.user_id;
    
    const serviceProvider = JSON.parse(JSON.stringify(provider));
    //delete serviceProvider.services;
    var totalPrice = 0;

    //console.log('selectedTreatmentsRef.current', selectedTreatmentsRef.current);
    if (!(selectedTreatmentsRef.current === undefined || selectedTreatmentsRef.current.length === 0)) {
      for (const treatmentIndex of selectedTreatmentsRef.current) {
        const treatment = props.dialogInfo.services[treatmentIndex];
        delete treatment.providers;
        delete treatment.users;
        treatments.push(treatment);
        totalPrice += props.dialogInfo.services[treatmentIndex].price;
        //console.log('treatment', props.dialogInfo.services[treatmentIndex]);
      }
    }
    */

    const customer = {} 
    if (authUser.user) {
      customer.uid = authUser.user.uid;
      customer.type = 'user';
    } else {
      customer.type = 'visitor';
    }
  
    const scheduleData = {
      fullname: guestFullnameRef.current, 
      email: guestEmailRef.current,
      phone: guestPhoneNumberRef.current, 
      address: addressEnteredRef.current,
      treatment: treatment, 
      date: selectedDay.format('MMM D'), 
      year: selectedDay.format('YYYY'), 
      time: bookingTimeSlot,
      totalPrice: totalPrice,
      customer: customer,
      datetime: Timestamp.now(),
      scheduleOrderDate:selectedDay.hour(0).minute(0).second(0).millisecond(0).unix(), // same date
      scheduleOrderTime: bookingTimeSlotNumber,
      scheduleTimeZone: selectedDay.format('z'),
      scheduleTimeOffset: selectedDay.format('ZZ')
    };

    //console.log('scheduleData', scheduleData);

    const scheduleRef = collection(firestoreInstance, '/schedules');

    const docRef = await addDoc(scheduleRef, scheduleData);

    if (docRef.id) {
      setSchedule({...scheduleData, scheduleId: docRef.id});
      setStep(2);
    }

    setActionBooking(false);
    //console.log("Document written with ID: ", docRef.id);
  }

  const bookAsGuest = () => {
    createSchedule();
  }
  
  const bookAsCustomer = () => {
    createSchedule();
  }

  const setSelectedTreatments = (selectedTreatments) => {
    selectedTreatmentsRef.current = selectedTreatments;
  }

  const [invalidPhoneNumber, setInvalidPhoneNumber] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const handlePhoneNumberChange = (newPhone) => {
    setPhone(newPhone);
  };

  useEffect(() => {
    guestPhoneNumberRef.current = phone;
  }, [phone]);

  useEffect(() => {
    if (authUser.user) {
      authUser.phone && setPhone(authUser.phone);
      setGuestFullname(authUser.user.displayName);
      setGuestEmail(authUser.user.email);
      authUser.user.address && setAddressEntered(authUser.user.address);
      console.log('authUser', authUser);
    }
  }, [authUser]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenTimeslotSnackbar(false);
  };

  function disableDates(date) {
    //console.log('date', date);
    const isBeforeToday = date.hour(0).minute(0).second(0).millisecond(0).unix() < dayjs().hour(0).minute(0).second(0).millisecond(0).unix();
    return isBeforeToday;
  }

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
          fullWidth
          maxWidth="md"
          sx={{
          "& .MuiDialog-paper": {
              margin: 0,
              width: "100%",
              'border-radius': 0,
              position: "absolute",
              bottom:0,
              height: "80vh"
          }
          }}
          BackdropProps={{
            invisible: true
          }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description-info"
      >
        <DialogTitle>
            <Stack direction="row" spacing={2}>
                <Item>
                    <Avatar alt={props.dialogInfo && props.dialogInfo.name} src={props.dialogInfo && props.dialogInfo.image} />
                </Item>
                <Item>
                    {props.dialogInfo && props.dialogInfo.name}
                </Item>
            </Stack>
            <ExpandMoreOutlinedIcon onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 18,
              }}
            />
            <Box>
              <BookingStepper step={step}/>
            </Box>
        </DialogTitle>
        <DialogContent ref={ref}>
          {step===0 && (
            <Grid container spacing={0}>
                {/*<Grid xs={12} md={12}>
                    {props.dialogInfo && props.dialogInfo.address}
                </Grid>*/}
                <Grid xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/*<StaticDateTimePicker />*/}
                        {/*<DateTimePicker sx={{width: "100%"}} />*/}
                        <DateCalendar value={selectedDay} onChange={(newValue) => selectDay(newValue)} shouldDisableDate={disableDates}/>
                    </LocalizationProvider>
                </Grid>
                <Grid xs={12} md={6}>
                  <Typography variant="subtitle2" align="left" gutterBottom>
                      Pick a Time
                  </Typography> 
                  <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                    {availableTimeSlots.map((timeslot, index) =>{return (
                      <>
                        {timeslot.userId != null ? (
                          <FlexboxItem onClick={()=>{startBooking(index+1, timeslot.userId);}}>{timeslot.timeSlot}</FlexboxItem>
                        ):(
                          <FlexboxItemText>{timeslot.timeSlot}</FlexboxItemText>
                        )}
                      </>
                    )})}
                  </Stack>
                </Grid>
            </Grid>
          )} 
          {step===1 && (
            <Box>
                <Typography variant="h6" align="center" sx={{mt:2}} gutterBottom>
                {bookingTimeSlot ? bookingTimeSlot : ""}, {selectedDay.format('MMM D')}
                </Typography>
                {!authUser.user && (
                  <Typography variant="subtitle2" align="right" gutterBottom>
                    Continue as a guest or 
                    <Button aria-label="sign in" color="inherit" sx={{textTransform: 'none'}} 
                    onClick={(e)=>{
                        e.preventDefault();
                        document.location.href = signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash
                      }}>
                      <LoginIcon sx={{marginRight: "5px"}}/> Sign In
                    </Button>
                  </Typography>
                )}
                {authUser.user ? (
                  <>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                      <FlexdivItem>
                        <TextField id="outlined-fullname" label="Full Name" variant="outlined" sx={{width: '100%'}} onChange={(e) => {setGuestFullname(e.target.value)}} defaultValue={guestFullnameRef.current}/>
                      </FlexdivItem>
                      <FlexdivItem>
                        <TextField id="outlined-email" label="Email" variant="outlined" sx={{width: '100%'}} onChange={(e) => {setGuestEmail(e.target.value)}} defaultValue={guestEmailRef.current}/>
                      </FlexdivItem>
                      <FlexdivItem>
                        <MuiTelInput sx={{width: '100%'}}
                            id="outlined-phone"
                            label="Phone"
                            required
                            error={invalidPhoneNumber}
                            value={phone} onChange={handlePhoneNumberChange}
                            defaultCountry='US'
                        />
                      </FlexdivItem>
                      <FlexdivItem>
                        <APIProvider apiKey={'AIzaSyBDB3_zlIS4f6qxog7HrvJCJ31rnQnBxxk'}>
                          <AddressInput setAddressEntered={setAddressEntered} label={`Please Enter Your Address`} clear={clear} setClear={setClear} defaultValue={addressEnteredRef.current}/>
                        </APIProvider>
                      </FlexdivItem>
                    </Stack>
                    {/*<AvailableTreatments services={props.dialogInfo.services} setSelectedTreatments={setSelectedTreatments} scrollRef={ref}/>*/}
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} >
                      <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={()=>{setStep(0)}}>
                        Back
                      </Button>
                      <LoadingButton
                        loading = {actionBooking}
                        loadingPosition="start"
                        startIcon={<EventAvailableIcon />}
                        variant="outlined"
                        onClick={bookAsCustomer}
                      >
                        Book
                      </LoadingButton>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                      <FlexdivItem>
                        <TextField id="outlined-fullname" label="Full Name" variant="outlined" sx={{width: '100%'}} onChange={(e) => {setGuestFullname(e.target.value)}} defaultValue={guestFullnameRef.current}/>
                      </FlexdivItem>
                      <FlexdivItem>
                        <TextField id="outlined-email" label="Email" variant="outlined" sx={{width: '100%'}} onChange={(e) => {setGuestEmail(e.target.value)}} defaultValue={guestEmailRef.current}/>
                      </FlexdivItem>
                      <FlexdivItem>
                        <MuiTelInput sx={{width: '100%'}}
                            id="outlined-phone"
                            label="Phone"
                            required
                            error={invalidPhoneNumber}
                            value={phone} onChange={handlePhoneNumberChange}
                            defaultCountry='US'
                        />
                      </FlexdivItem>
                      <FlexdivItem>
                        <APIProvider apiKey={'AIzaSyBDB3_zlIS4f6qxog7HrvJCJ31rnQnBxxk'}>
                          <AddressInput setAddressEntered={setAddressEntered} label={`Please Enter Your Address`} clear={clear} setClear={setClear} defaultValue={addressEnteredRef.current}/>
                        </APIProvider>
                      </FlexdivItem>
                    </Stack>
                    {/*<AvailableTreatments services={props.dialogInfo.services}  setSelectedTreatments={setSelectedTreatments} scrollRef={ref}/>*/}
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{mt: 2}}>
                      <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={()=>{setStep(0)}}>
                        Back
                      </Button>
                      <LoadingButton
                        loading = {actionBooking}
                        loadingPosition="start"
                        startIcon={<EventAvailableIcon />}
                        variant="outlined"
                        onClick={bookAsGuest}
                      >
                        Book
                      </LoadingButton>
                    </Stack>
                  </>
                )}
            </Box>
          )}
          {step===2 && (
            <Box>
              <Typography variant="h6" align="center" sx={{mt:2}} gutterBottom>
                Booking Details
              </Typography> 
              {schedule && (
                <>
                  <ScheduleCard schedule={schedule} scrollRef={ref}/>
                </>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openTimeslotSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={timeslotSnackbarMessage}
        action={snackbarAction}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
}
