import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled, TextField } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc, limit } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import VimeoPlayer from "../template/VimeoPlayer";
import Hero from "./GraphQL/Hero";
import SpaceX from "./GraphQL/SpaceX";
import NFT from "./GraphQL/NFT";
import Matching from "./Matching";
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import {QueryAddress, PlaceAutocomplete} from "./google_maps/MapComponents";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Home = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    const currentCircle = useRef(null);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    // LA 34.054908,-118.242643
    // Vancouver 49.2827291 -123.1207375
    //const position = {lat: 49.2827291, lng: -123.1207375};
    const [position, setPosition] = useState({lat: 34.054908, lng: -118.242643});
    const setMarker = (lat, lng) => {
      setPosition({lat: lat, lng: lng});
    }
    const [addressEntered, setAddressEntered] = useState('');

    return (
        <>
            {loaded ? (
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="IV Services" />
                     <Box>
                      <img src={matchDownMd ? 'https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/images%2Flivingwaters-11.png?alt=media&token=525cf3ef-5089-45de-b1c1-e7afbbf1fc63' : 'https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/images%2Flivingwaters-7.png?alt=media&token=98535653-707c-47d1-835e-b499621a318a'} style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}}/>
                     </Box>
                     <Box sx={{ flexGrow: 1, px: 2, py: 0, width: '100%', minHeight: '100vh'}} >
                     {authUser.user && authUser.userType === "Nurse" ? (
                      <>
                        Please log in as a Customer to access the services.
                      </>) : (
                      <>
                        <Grid container spacing={0}>
                          <Grid xs={12} md={12}>
                          <APIProvider apiKey={'AIzaSyBDB3_zlIS4f6qxog7HrvJCJ31rnQnBxxk'}>
                            <PageSection>
                              <Grid container spacing={0}>
                                {/*<Grid xs={12} md={12}>
                                <Box sx={{minHeight: '400px', height: '100%'}}>
                                  <Map center={position} zoom={10} disableDefaultUI={true} scrollwheel={false} gestureHandling={'none'} keyboardShortcuts={false}>
                                  <Marker position={position} />
                                  </Map>
                                  <QueryAddress setMarker={setMarker} currentCircle={currentCircle} />
                                </Box>
                                </Grid>*/}
                                <Grid xs={12} md={12} sx={{px: {xs:0, md:2}}}>
                                  <PlaceAutocomplete setMarker={setMarker} currentCircle={currentCircle} showCircle={false} setAddressEntered={setAddressEntered} label={`Enter your address for available services`}/>
                                </Grid>
                              </Grid>
                            </PageSection>
                          </APIProvider>
                          </Grid>
                          <Grid xs={12} md={12}>
                            <Matching position={position}/>
                          </Grid>
                          {/*
                          <Grid xs={12} md={4}>
                            <PageSection>
                              <VimeoPlayer embedId="606276910" />
                            </PageSection>
                          </Grid>
                          */}
                          <Grid xs={12} md={12}>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={4}>
                                  <PageSection>
                                    
                                  </PageSection>
                                </Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={12} md={12}>
                          <PageSection>
                            
                          </PageSection>
                          </Grid>
                        </Grid>
                      </>
                     )}
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Home;
