import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, CardMedia, CardContent, Container, Grid, Paper, Typography, styled, TextField } from "@mui/material";
import Fab from '@mui/material/Fab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Messaging from '../components/Messaging';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, Timestamp, orderBy, and, documentId } from 'firebase/firestore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { blueGrey, green } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import {QueryAddress, PlaceAutocomplete, Direction} from "../components/google_maps/MapComponents";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SendIcon from '@mui/icons-material/Send';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function ServiceStatus(props) {
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { authUser } = useContext(AuthContext);
    const { item, ...other } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [
        'On the way',
        'Will arrive in 30 minutes',
        'Arriving now',
    ];

    //console.log(item);
    //console.log('service', typeof(item.service) !== 'undefined' ? 'service' : 'service not defined');
    
    /*if (typeof(item.serviceStep) !== 'undefined') {
        setActiveStep(item.serviceStep);
    }*/
    useEffect(() => {
        if (typeof(item.serviceStep) !== 'undefined') {
            setActiveStep(item.serviceStep);
        }
    },[]);

    const sendMessage = async () => {
        //update schedules doc
        /*
        const schedulesRef = collection(firestoreInstance, '/schedules');
        const scheduleQuery = query(schedulesRef, where(documentId(), '==', item.id));
        if (authUser.user) {
            await Promise.all([getDocs(scheduleQuery)]).then(([snapshot]) => {
                snapshot.forEach(record => {
                    const schedule = record.data();
                    console.log('schedule', schedule);
                });
            }).catch(error => {
                console.log('error', error);
            });
        }*/

        const schedulesRef = doc(firestoreInstance, 'schedules/'+item.id);
        setDoc(schedulesRef,{
            serviceStep: activeStep + 1
        },{merge:true}).then(() => {
            setActiveStep(activeStep + 1); 
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            {activeStep >= 3 ? (
                <>
                    <Typography component='div' align='center' sx={{mt:2}}>
                        Call your customer if ...
                    </Typography>
                </>
            ) : (
                <>
                    <Typography component='div' align='center' sx={{mt:2}}>
                    <Button variant="outlined" endIcon={<SendIcon />} onClick={()=>{sendMessage();}}>
                        Notify Customer
                    </Button>
                    </Typography>
                    <Typography component='div' align='center' sx={{mt:2, fontSize: '11px'}}>
                        Customer will be notified by Text Message and Email.
                    </Typography>
                </>
            )}
        </Box>
    );
}

function ScheduleCard(props) {
    const { item, setShowAppointment, setCurrentAppointment, ...other } = props;
    //console.log('item', item);
  
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
  
    return (
      <Card sx={{ width: '100%', mb: 2 }}>
        <CardContent>
          <Grid container spacing={0}>
              <Grid xs={12} md={12}>
                  <CardHeader sx={{px:0, pt:0, cursor: 'pointer'}}
                      avatar={
                          <CardMedia
                          component="img"
                          sx={{ maxHeight: 56, maxWidth: 56, bgcolor: green[50], borderRadius: 1, p: '4px', width: 56, height: 56}}
                          image={item.treatment.image}
                          alt={item.name}
                          />
                      }
                      title={item.treatment.name}
                      subheader={
                          <>
                              <Typography>
                              {item.time}
                              </Typography>
                              <Typography sx={{fontSize: 11}}>
                              ${item.totalPrice}
                              </Typography>
                          </>
                      }
                      onClick={()=>{
                        setCurrentAppointment(item);
                        setShowAppointment(false);
                      }}
                  />
              </Grid>
              <Grid xs={12} md={12}>
                  <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                      <AccountCircleIcon sx={{mr:1}}/>{item.fullname}
                  </Typography>
              </Grid>
              <Grid xs={12} md={12}>
                  {/*
                  <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                      <PhoneIcon sx={{mr:1}}/>{item.phone}
                  </Typography>
                  */}
                  <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                      <PlaceIcon sx={{mr:1}}/>{item.address}
                  </Typography> 
              </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NurseQuickTools() {
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const { authUser } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
  const [selectedYearMonth, setSelectedYearMonth] = React.useState(selectedDay.format('MMM')+' '+selectedDay.format('YYYY'));
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [schedules, setSchedules] = useState([]);
  //const [position, setPosition] = useState({lat: 37.77, lng: -122.447});
  //const [startPosition, setStartPosition] = useState({ lat: 37.77, lng: -122.447 });
  //const [endPosition, setEndPosition] = useState({ lat: 37.768, lng: -122.511 });

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [showAppointment, setShowAppointment] = useState(true);
  const [currentAppointment, setCurrentAppointment] = useState(null);

  const ref = React.useRef(null);

  const selectDay = (day) => {
    setSelectedDay(day);
  }

  const selectMonth = (month) => {
    //console.log('month', month.format('MMM')+' '+month.format('YYYY'));
    setSelectedYearMonth(month.format('MMM')+' '+month.format('YYYY'));
    //setSelectedDay(dayjs(new Date())); // for showing appointments of the day
  }

  const updatePosition = () => {
    //console.log('chat with ref', ref.current);
    if (ref.current) {
        //console.log('chat with scroll', ref.current);
        ref.current.scrollTop = 10000;//ref.current.offsetHeight;
        //ref.current.ownerDocument.body.scrollTop = ref.current.ownerDocument.body.offsetHeight;
    }
  }

  const getHighlightedDays = useCallback(async (yearMonth) => {
    //get appointment days of the month
    //console.log('get appointment of ', yearMonth);
    const calendarRef = collection(firestoreInstance, '/appointmentCalendar');
    const calendarQuery = query(calendarRef, 
        and(where('yearMonth', '==', yearMonth), where('uid', '==', authUser.user.uid)));
    let days = [];
    if (authUser.user) {
        await Promise.all([getDocs(calendarQuery)]).then(([calendarSnapshot]) => {
            calendarSnapshot.forEach(record => {
                const calendar = record.data();
                //console.log('calendar', calendar.days);
                days = calendar.days;
            });
        }).catch(error => {
            console.log('error', error);
        });
        //console.log('days', days);
        //str to int
        setHighlightedDays(days.map(i=>Number(i)));
    }
  }, [selectedYearMonth]);

  useEffect(() => {
    getHighlightedDays(selectedYearMonth);
  },[getHighlightedDays]);

  const getSchedules = useCallback(async (date) => {
    //get schedules (appointments) of the day
    //console.log('get appointment of ', date.format('MMM D'), date.format('YYYY'));
    // read from firestore DB
    const schedulesRef = collection(firestoreInstance, '/schedules');
    const scheduleQuery = query(schedulesRef, 
        and(where('date', '==', date.format('MMM D')), where('year', '==', date.format('YYYY')), where('treatment.provider.user_id', '==', authUser.user.uid)),
        //orderBy("datetime", "desc"),
        orderBy("scheduleOrderDate", "asc"),
        orderBy("scheduleOrderTime", "asc"));
    const schedules = [];
    if (authUser.user) {
        await Promise.all([getDocs(scheduleQuery)]).then(([scheduleSnapshot]) => {
            scheduleSnapshot.forEach(record => {
                const schedule = record.data();
                schedule.id = record.id;
                //console.log('schedule', schedule);
                schedules.push(schedule);
            });
        }).catch(error => {
            console.log('error', error);
        });
        //console.log('schedules', schedules);
    }
    setSchedules(schedules);
  }, [selectedDay]);

  useEffect(() => {
    getSchedules(selectedDay);
  },[getSchedules]);

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  
    const isSelected =
      !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '💧' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  }

  return (
    <div>
      <Fab color="primary" aria-label="Sensors" style={{position: 'fixed', bottom: 80, right: 20}} onClick={handleClickOpen}>
        <CalendarMonthOutlinedIcon />
      </Fab>
      <Dialog
          fullWidth
          maxWidth="md"
          sx={{
          "& .MuiDialog-paper": {
              margin: 0,
              width: "100%",
              'border-radius': 0,
              position: "absolute",
              bottom:0,
              height: "80vh"
          }
          }}
          BackdropProps={{
            invisible: true
          }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <>
            {showAppointment ? (
              <>Appointments</>
            ) :(
              <>
                <Button variant="text" size="small" startIcon={<ArrowBackIcon />} onClick={()=>{setShowAppointment(true)}}>
                  Back
                </Button>
                <Typography component='span' align='left' sx={{fontWeight: 'bold', pl: 1}}>
                {currentAppointment && currentAppointment.time}, {currentAppointment && currentAppointment.date}, {currentAppointment && currentAppointment.year}
                </Typography>
              </>
            )}
            <ExpandMoreOutlinedIcon onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 18,
              }}
            />
          </>
        </DialogTitle>
        <DialogContent ref={ref}>
          <DialogContentText id="nurse-quick-tools-dialog">
            <Grid container spacing={0}>
                {showAppointment ? (
                <>
                    <Grid xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={selectedDay} onMonthChange={(newValue) => selectMonth(newValue)} onChange={(newValue) => selectDay(newValue)} 
                                slots={{
                                day: ServerDay,
                                }}
                                slotProps={{
                                day: {
                                    highlightedDays,
                                },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography component="div" variant="subtitle1" align="center" sx={{fontWeight: 'bold'}}>
                            {selectedDay.format('MMM D')}, {selectedDay.format('YYYY')}
                        </Typography> 
                        {schedules.length > 0 ? (
                            <>
                            {schedules.map((item, index) => (
                                <ScheduleCard item={item} setShowAppointment={setShowAppointment} setCurrentAppointment={setCurrentAppointment} />
                            ))}
                            </>
                        ) : (
                            <Typography variant="subtitle2" align="left" gutterBottom>
                                No Appointment.
                            </Typography> 
                        )}
                    </Grid>
                </>) : (
                <>
                    <Grid xs={12} md={6}>
                        <Typography color="text.secondary" component="div" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                            <WaterDropIcon sx={{mr:1}}/>
                            <Typography component='span' align='left' color="text.primary" sx={{fontWeight: 'bold'}}>
                            {currentAppointment && currentAppointment.treatment.name}
                            </Typography>
                        </Typography> 
                        <Typography color="text.secondary" component="div" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                            <AccountCircleIcon sx={{mr:1}}/>{currentAppointment && currentAppointment.fullname}
                        </Typography> 
                        <Typography color="text.secondary" component="div" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                            <PlaceIcon sx={{mr:1}}/>{currentAppointment && currentAppointment.address}
                        </Typography>
                        {!matchDownMd && (
                            <Box sx={{ display: { xs: 'none', md: 'block' }, mr:3, bgcolor: '#F0F0F0', py:2 }}>
                                <ServiceStatus item={currentAppointment}/>
                            </Box>
                        )}
                    </Grid>
                    <Grid xs={12} md={6}>
                        {currentAppointment && (
                            <APIProvider apiKey={'AIzaSyBDB3_zlIS4f6qxog7HrvJCJ31rnQnBxxk'}>
                            <Box sx={{minHeight: '300px', height: '100%'}}>
                                <Map mapTypeControl={false} keyboardShortcuts={false}/>
                            </Box>
                            <Direction appointment={currentAppointment} />
                            </APIProvider>
                        )}
                    </Grid>
                    {matchDownMd && (
                        <Grid xs={12} sx={{ display: { xs: 'block', md: 'none' }, pt:8 }}>
                            <Box sx={{bgcolor: '#F0F0F0', py:2}}>
                                <ServiceStatus item={currentAppointment}/>
                            </Box>
                        </Grid>
                    )}
                </>)}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
